<template>
    <div class="invite-detail">
        <van-nav-bar placeholder fixed left-arrow :border='false' title="邀请详情" z-index="99" class="header"
            @click-left="$router.back()">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33" />
            </template>
        </van-nav-bar>
        <div class="bg_invite-friend_buttom"></div>
        <div class="invite-record">
            <div class="mytable">
                <table>
                    <thead>
                        <tr>
                            <th>好友名称</th>
                            <th>时间</th>
                            <th>状态</th>
                        </tr>
                    </thead>
                    <tbody v-if="tableData.length > 0">
                        <tr v-for="item in tableData" :key="item.id" >
                            <td>{{ item.userInfo.nickname }}</td>
                            <td>{{ item.created_at }}</td>
                            <td class="success">邀请成功</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button class="invite-button" @click="button">邀请更多好友</button>
        </div>


    </div>
</template>

<script>
import { NavBar, Icon } from 'vant';
export default {
    name: "InvitDeatil",
    data() {
        return {
            tableData: [{
                id: 0,
                user_name: '',
                created_at: '',
                status: '',
                userInfo: {
                    id: 0,
                    avatar: '',
                    nickname: '',
                },
            },],
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            console.log('获取邀请信息');
            let data = {
                page: 1,
                page_size: 50,
                one_uid: this.user_id
            };
            this.$axios.post("/api/user/get_invite_record", data).then((res) => {
                console.log(res.data.data);
                this.tableData = res.data.data.list.map(item => ({
                    ...item,
                    userInfo: {
                        ...item.userInfo,
                    },
                }));

            });

        },
        button() {
            console.log('邀请好友');
            if (Android) {
                Android.invite();
            }
        },
    },
}
</script>

<style scoped>
.title {
    margin-bottom: 40px;
    text-align: center;
}

.invite-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

.invite-record {
    display: flex;
    flex-direction: column;
    background: #F1F7FF;
    align-items: center;
    border-radius: 20px;
    height: 80vh;
    margin-top: 100px;
    width: 80%;
    box-shadow: 0px 10px 30px 0px rgba(0, 22, 255, 0.1);
    text-align: center;
    z-index: 1;
}

.mytable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    max-height: 50%;
    overflow: auto; /* 允许内容溢出时滚动 */
    z-index: 1;
}

thead th {
    color: gray;
    text-align: left;
    padding: 15px;
}

tbody td {
    padding: 15px;
    text-align: left;
    color: #6E706F;
}

.success {
    color: green;
}

.bg_invite-friend_buttom {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom, #BAE2FD, #2C84FB);
    background-size: 100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-clip: padding-box;
    z-index: 0;
    padding-bottom: 20px;
    overflow: hidden;
}

.invite-button {
    background: #2C84FB;
    color: white;
    padding: 20px 20px;
    border: none;
    width: 60%;
    height: 70px;
    border-radius: 40px;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 100px;
    margin-top: 60px;
    position: relative;
    bottom: 0;
    cursor: pointer;
}
</style>

