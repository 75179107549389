<template>
    <div class="invite-friend">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="邀请好友"
                z-index="99"
                @click-left="back()">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="bg_invite-friend_top"></div>

        <div class="invite-title">邀请好友赢金币</div>

        <div>
        </div>

        <div class="invite-body">
            <div class="reward-box">
            </div>
            <div class="reward-box-cover">
                <p class="invite-info">邀请您的好友一起听书吧</p>
            </div>
        </div>
        <div class="invite-body-box">
            <div class="countdown">
                剩余时间: <span class="time-show"> 5</span> 天 <em class="time-show">30</em> 分 <em class="time-show">60</em>
                秒
            </div>
            <button class="invite-button" @click="button">邀请好友</button>
        </div>

        <div class="invite-body-box-more"></div>

        <div class="invite-footer">
            <p @click="rulesClick">活动规则</p>
            <p @click="handleCash">去提现</p>
        </div>
        <div class="invite-record">
            <div class="record-title">邀请记录</div>
            <table>
                <thead>
                    <tr>
                        <th>好友名称</th>
                        <th>时间</th>
                        <th>状态</th>
                    </tr>
                </thead>
                <tbody v-if="tableData.length > 0">
                    <tr v-for="item in tableData" :key="item.id">
                        <td>{{ item.userInfo.nickname }}</td>
                        <td>{{ item.created_at }}</td>
                        <td class="success">邀请成功</td>
                    </tr>
                </tbody>
            </table>
            <div class="line"></div>
            <p class="view-details-button" @click="detailClick">查看详情</p>
        </div>
        <!-- <div class="invite-bg-other"></div> -->
        <!--        分享面板-->
        <!-- <van-overlay :show="showShare" @click="showShare = false">
            <Search></Search>
        </van-overlay> -->

        <div class="bg_invite-friend_buttom"></div>
    </div>

</template>


<script>
import Search from "../components/Search";

let wx = require('weixin-js-sdk');
export default {
    components: {
        Search
    },
    name: "Invitation",
    data() {
        return {
            showShare: false,
            user_id: this.$route.query.uid,
            ruleList: [],
            tableData: [{
                id: 0,
                user_name: '',
                created_at: '',
                status: '',
                userInfo: {
                    id: 0,
                    avatar: '',
                    nickname: '',
                },
            },],
            reward_number: "",
        }
    },
    created() {
        // this.rule()
        this.jssdk()
        this.getList()
    },
    methods: {
        back() {
            if (Android) {
                Android.Back();
            }
        },
        getList() {
            //this.$toast("haha")
            console.log('获取邀请信息:'+this.user_id);
            let data = {
                page: 1,
                page_size: 5,
                one_uid: this.user_id
            };
            this.$axios.post("/api/user/get_invite_record", data).then((res) => {
                console.log(res.data.data);
                this.tableData = res.data.data.list.map(item => ({
                    ...item,
                    userInfo: {
                        ...item.userInfo,
                    },
                }));

                this.reward_number = res.data.data.reward_number;
            });

        },
        jssdk() {
            this.$axios.post('/api/wechat/jssdk', {
                url: document.location.href,
            })
                .then(res => {
                    var res = res.data.data
                    res.share.link = res.share.link + this.user_id
                    wx.config(res.jssdk);
                    wx.checkJsApi({
                        jsApiList: res.jssdk.jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
                        success: function (res) {
                            // 以键值对的形式返回，可用的api值true，不可用为false
                            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                        }
                    });
                    wx.ready(() => {   //需在用户可能点击分享按钮前就先调用
                        wx.updateAppMessageShareData({
                            title: res.share.sharetitle, // 分享标题
                            desc: res.share.sharedescribe, // 分享描述
                            link: res.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: res.share.sharepic, // 分享图标
                            success: () => {
                                // 设置成功
                            }
                        })
                    });
                    wx.ready(() => {      //需在用户可能点击分享按钮前就先调用
                        wx.updateTimelineShareData({
                            title: res.share.sharetitle, // 分享标题
                            link: res.share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: res.share.sharepic, // 分享图标
                            success: () => {
                                // 设置成功
                            }
                        })
                    });
                })
                .catch(err => {
                    this.$toast('获取失败')
                })
        },
        rule() {
            this.$axios.get('/invite/rule', {
                params: {
                    user_id: this.user_id,
                }
            })
                .then(res => {
                    this.ruleList = res.data.data
                })
        },
        rulesClick() {
            this.$router.push({
                path: '/rules'
            })
        },
        detailClick() {
            console.log('查看详情');
            this.$router.push({
                path: "/invitDeatil",
                query: {
                    keywords: this.input,
                    type: this.type,
                    date:new Date().getTime()
                }
            })
        },
        button() {
            console.log('邀请好友');
            if (Android) {
                Android.invite();
            }
        },
        handleCash() {
            console.log('去提现');
            if (Android) {
                Android.withdraw();
            }
        }
    }
}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.header {
    position: relative;
    width: 100%;
    background: white;
    padding: 20px;
    text-align: center;
    color: black;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.invite-friend {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

.bg_invite-friend_top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 60%;
    height: 60vh;
    background-image: linear-gradient(to bottom, #2C84FB, #f1f1f4);
    background-size: 100% 100%;
    background-position: center top, center bottom;
    background-repeat: no-repeat;
    background-clip: padding-box;
    z-index: 0;
}

.bg_invite-friend_buttom {
    flex: 1;
    position:absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-image: linear-gradient(to bottom, #BAE2FD, #2C84FB);
    background-size: 100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-clip: padding-box;
    border-top-left-radius: 50% 110px;
    border-top-right-radius: 50% 110px;
    z-index:0;
    padding-bottom: 20px;
}

.back-button {
    position: absolute;
    left: 20px;
    top: 20px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.header h1 {
    margin: 0;
    font-size: 24px;
}

.invite-title {
    font-size: 70px;
    margin-top: 60px;
    color: white;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 80px;
}

.invite-body {
    position: relative;
    background: #FBFBF6;
    width: 55%;
    height: 600px;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    margin-top: -20px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 30px 0px rgba(0, 22, 255, 0.1);
    z-index: 1;
}


.invite-body-box {
    position: relative;
    width: 60%;
    background: #E95946;
    height: 270px;
    /* Half of the height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -270px;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    flex-direction: column;
    z-index: 2;
}

.invite-body-box-more {
    position: relative;
    width: 60%;
    background: #BA3327;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    margin-top: -400px;
    flex-direction: column;
    z-index: -1;
}

.reward-box {
    position: absolute;
    width: 90%;
    border: 2px solid #ffd700;
    /* 1px宽的黑色实线边框 */
    background-size: cover;
    /* 背景图片覆盖整个元素 */
    border-radius: 10px;
    padding: 10px;
    height: 500px;
    margin-bottom: 20px;
}

.reward-box-cover {
    position: relative;
    z-index: 1;
}

.invite-info {
    margin-top: 50px;
    font-size: 55px;
    margin-bottom: 50px;
    color: #C25A50;
    letter-spacing: 6px; /* 字体间距 */
}

.invite-money {
    font-size: 80px;
    color: #C25A50;
}

.countdown {
    color: white;
    font-size: 24px;
    display: none;
    margin-bottom: 30px;
}

.time-show {
    background: #BE372A;
    padding: 5px;
    padding-left: 5px;
}

.invite-button {
    background: linear-gradient(to bottom, #FEFCF2, #F9E1A5);
    color: #E0404A;
    padding: 20px 20px;
    border: none;
    width: 60%;
    height: 70px;
    border-radius: 40px;
    font-size: 26px;
    position: relative;
    bottom: 0;
    cursor: pointer;
}

.invite-footer {
    display: flex;
    justify-content: space-around;
    width: 80%;
    font-weight: bold;
    margin-top: 80px;
    color: #4b71ed;
    margin-bottom: 90px;
    padding: 10px 0;
    text-decoration: underline;
    z-index: 1;
}

.invite-record {
    width: 90%;
    background: #F1F7FF;
    border-radius: 20px;
    padding: 20px;
    align-items: center;
    margin-top: -20px;
    box-shadow: 0px 10px 30px 0px rgba(0, 22, 255, 0.1);
    text-align: center;
    position: relative;
    top: -40px;
    z-index: 1;
}

.price {
    font-size: 40px;
}

.record-title {
    margin-bottom: 10px;
    font-size: 26px;
    width: 25%;
    font-weight: bold;
    color: #4d77fd;
    background: #BAE2FD;
    border-radius: 10px;
    padding: 15px;
    margin-top: -40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    margin-bottom: 10px;
}

thead th {
    color: gray;
    text-align: left;
    padding: 15px;
}

tbody td {
    padding: 15px;
    text-align: left;
    color: #6E706F;
}

.success {
    color: green;
}

.in-progress {
    color: #4d77fd;
}

.line {
    height: 5px;
    background: #FdFbFF;
    margin-bottom: 15px;
}

.view-details-button {
    color: #4d77fd;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-size: 26px;
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin: 0 auto;
}

.invite-bg-other {
    background: #ea0707;
}
</style>