<template>
    <div id="share">
        <div class="row view-alert" v-if="wxshow"
             style="z-index: 99; width: 100%; background: rgb(52, 52, 52); margin-left: 0px; margin-right: 0px; padding-top: 30px; padding-bottom: 30px; box-shadow: rgba(22, 22, 23, 0.62) 0px -10px 16px inset;">
            <div class="col-md-12 text-left" style="padding-right:50px;padding-left:30px;">
                <p style="color:#7f7e7e;margin:0px;">点击右上角按钮，然后在弹出的菜单中，点击在浏览器中打开，即可安装</p>
            </div>
            <div class="col-md-12 pull-right">
                <img src="../assets/image/alert-arrow.png" class="pull-right"
                     style="width:30px;margin-top:-68px;position: absolute;right: 5px">
            </div>
        </div>
        <div class="input-box">
            <input type="text" placeholder="输入手机号，下载APP去看吧" v-model="phone"
                   @input="checkPhone">
        </div>
        <div :class="buttonClick?'button':'button stop'" @click="downloadApp">{{webName}}</div>
    </div>
</template>

<script>
  export default {
    name: 'share',
    data() {
      return {
        buttonClick: false,
        phone: '',
        share_uid: '',
        android_download_url: '',
        ios_download_url: '',
        webName: '',
        wxshow: false,
        mch: '',
      }
    },
    created() {
      if (this.isWeiXin()) {
        this.wxshow = true;
      }
      let url = this.getUrl();
      this.share_uid = url.share_uid;
      this.mch = url.mch;
      this.$axios({
        url:'/share/download_url',
        method: 'get',
      }).then((res) => {
        console.log(res.data.data);
        let data = res.data.data;
        this.android_download_url = data.android_download_url;
        this.ios_download_url = data.ios_download_url;
        this.webName = '下载' + data.name
      }).catch((err) => {
        alert(err.message)
      });
    },
    methods: {
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
      checkPhone() {
        this.phone = this.phone.replace(/\D/g, '');
        var re = /^1\d{10}$/;
        let str = this.phone;
        if (re.test(str)) {
          this.buttonClick = true;
        } else {
          this.buttonClick = false;
        }
      },
      downloadApp() {
        if (this.buttonClick) {
          this.$axios({
            url: '/share/bind_phone',
            method: 'get',
            params: {
              share_uid: this.share_uid,
              phone: this.phone,
              mch: this.mch
            }
          }).then((res) => {
            var u = window.navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            console.log(this.android_download_url);
            console.log(this.ios_download_url);
            if (isAndroid) {
              window.location.href = this.android_download_url
            }
            if (isIOS) {
              window.location.href = this.ios_download_url
            }
          }).catch((err) => {
            alert(err.message)
          });
        }
      },
    }
  }
</script>
<style scoped lang="less">
    #share {
        min-height: 100%;
        background: url("../assets/image/share_bg.png");
        background-size: 100% 100%;

        .input-box {
            position: fixed;
            left: 50%;
            bottom: 131px;
            transform: translateX(-50%);
            width: 640px;
            height: 88px;
            border-radius: 10px;

            input {
                outline: none;
                border: none;
                width: 100%;
                height: 100%;
                background: rgba(241, 248, 250, 0.5);
                text-indent: 80px;
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            input::-webkit-input-placeholder {
                color: #999999;
                font-size: 30px;
            }

            /* Mozilla Firefox 4 to 18 */

            input:-moz-placeholder {
                color: #999999;
                opacity: 1;
                font-size: 30px;
            }

            /* Mozilla Firefox 19+ */

            input::-moz-placeholder {
                color: #999999;
                opacity: 1;
                font-size: 30px;
            }

            /* Internet Explorer 10+ */

            input:-ms-input-placeholder {
                color: #999999;
                font-size: 30px;
            }
        }

        .input-box:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 27px;
            background: url("../assets/image/phone.png");
            background-size: 100% 100%;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);

        }

        .button {
            width: 300px;
            height: 70px;
            background: linear-gradient(90deg, rgba(78, 120, 253, 1) 0%, rgba(119, 151, 255, 1) 100%);
            opacity: 0.5;
            border-radius: 35px;
            position: fixed;
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);
            text-align: center;
            line-height: 70px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
        }

        .button.stop {
            background: #999999;
        }
    }
</style>
