import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/rem';
import axios from 'axios';
import './assets/css/index.css';
import 'vant/lib/index.css';
import qs from 'qs';
import Bridge from './utils/bridge.js';
import MetaInfo from 'vue-meta-info';
import { PullRefresh, Icon, Toast, NavBar,CellGroup,Col,Row } from 'vant';

Vue.prototype.$bridge = Bridge;

Vue.use(PullRefresh);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);

Vue.use(MetaInfo)
Vue.config.productionTip = false;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('Request URL:', config.url);
    console.log('Request Params-:', JSON.stringify(config.params));
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log(JSON.stringify(response.data));
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//`http://120.26.162.172:80/`;
// `https://www.sjinhu.com/`;

axios.defaults.baseURL = `https://www.sjinhu.com/`;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.getUrl = function () {
    let url = window.location.href;
    url = decodeURIComponent(url.substring(url.lastIndexOf("?") + 1));
    let index;
    if ((index = url.indexOf("#")) > 0)
        url = url.substring(0, index);
    let params = {};
    url.split("&").forEach(v => {
        let index;
        if ((index = v.indexOf("=")) > 0) {
            params[v.substring(0, index)] = v.substring(index + 1);
        }
    });
    return params;
};

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
