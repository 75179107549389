<template>
    <div id="myfirend">
        <div class="myfirend-box">
            <div class="myfirend-box-item" v-for="(item,index) in firendData" :key="index">
                <span class="userIndex">{{index+1}}</span>
                <img :src="item.avatar"
                     alt="" class="userImg">
                <span class="username">{{item.nickname}}</span>
            </div>
            <div class="nodata" v-if="firendData.length<1">暂无数据,快去邀请好友吧</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "myfirend",
        data() {
            return {
                firendData: [],
                user_id: '',
            }
        },
        created() {
            this.user_id = this.$route.query.user_id;
            console.log(this.user_id);
            this.$axios({
                url: '/h5/getFriendList',
                method: 'get',
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                let data = res.data.data;
                this.firendData = data;
            }).catch((err) => {
                alert(err.message)
            });
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    #myfirend {
        .myfirend-box {
            padding: 40px;

            .myfirend-box-item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #333333;

                .userIndex {
                    width: 100px;
                    text-align: center;
                    font-size: 30px;
                }

                .userImg {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }

                .username {
                    font-size: 30px;
                    text-indent: 20px;
                }
            }

            .nodata {
                text-align: center;
            }
        }
    }
</style>