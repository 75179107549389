<template>
    <div id="app" class="scrollable-content">
        <div class="content">
            <div class="header-content">
                <div class="income-statistic">
                    <div class="income-statistic-wrapper">
                        <div class="income-statistic-wrapper-item border-right" @click="handleCash()">
                            <div style="margin-bottom: 20px;">
                                <span class="title">金币收益</span>
                                <img src="../assets/image/right-arrow.png"
                                    style="width: 20px;height: 20px;vertical-align: middle;" alt="" />
                            </div>
                            <div>
                                <span class="money">{{ wealBean.coin }}</span>
                                <span class="unit">币</span>
                            </div>
                        </div>
                        <div class="income-statistic-wrapper-item" style="display: none;">
                            <div style="margin-bottom: 20px;">
                                <span class="title">现金收益</span>
                                <img src="../assets/image/right-arrow.png"
                                    style="width: 20px;height: 20px;vertical-align: middle;" alt="" />
                            </div>
                            <div class="">
                                <span class="money">0.01</span>
                                <span class="unit">元</span>
                            </div>
                        </div>
                        <div class="income-statistic-wrapper-item">
                            <button class="right-btn" @click="handleCash()">去提现</button>
                        </div>
                    </div>
                    <div class="desc">
                        金币每天凌晨自动兑换成现金，兑换可能存在延时
                    </div>
                </div>
                <!-- 签到 -->
                <div class="sign-wrapper">
                    <div class="sign-wrapper-top">
                        <div class="sign-wrapper-top-left">
                            <div class="day-content">
                                本周已签到<span class="day-num">{{ countIsTypeOne }}</span>天
                            </div>
                            <div class="next-day-content">
                                明日签到可得{{ tomorrowCoin }}金币
                            </div>
                        </div>
                        <button class="view-detail-btn" @click="handleDetail()">去签到</button>
                    </div>
                    <div class="sign-wrapper-bottom">
                        <div class="sign-wrapper-bottom-item" v-for="item in wealBean.sign_config" :key="item.id"
                            :class="{ 'active-item': item.is_type === 1 }">
                            <div class="sign-wrapper-bottom-item-left">
                                <img src="../assets/image/coin2.png" style="width: 20px;height: 20px;display: block;"
                                    alt="" />
                                <span class="coins">{{ item.coin }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 活动 -->
            <div class="activity-wrapper">
                <div class="activity-wrapper-content">
                    <div class="activity-title">看8个视频赚3000金币</div>
                    <div class="activity-today-content">
                        今日累计发放<span class="total-num">208584.9</span>万金币
                    </div>
                    <div class="activity-btn">
                        <button @click="handleActivity()">参与活动赚金币</button>
                    </div>
                </div>
            </div>

            <!-- 听书福利 -->
            <div class="listen-wrapper">
                <div class="listen-wrapper-top">
                    <div class="listen-wrapper-top-left">
                        <div class="listen-wrapper-top-des">
                            每日听书福利
                            <img src="../assets/image/coin2.png"
                                style="width: 14px; height: 14px;vertical-align: middle;margin: 0 2px;" alt="" />
                            <span class="tip">听书赚金币</span>
                        </div>
                        <div class="today-listen">
                            今日已听 <span class="munites">{{ wealBean.today_read_time }}</span> 分钟
                        </div>
                    </div>
                    <button @click="handleToListen()">去听书</button>
                </div>
                <div class="listen-wrapper-center">{{ calculateReadTime }}</div>
                <div class="listen-list">
                    <div class="listen-list-item" v-for="item in wealBean.daily_read_config" :key="item.id"
                        :class="{ 'active-item': item.status === '2' }"
                        @click="handleGetCoin(item.status, item.coin, item.id)">
                        <img :src="item.status === '0' ? require('../assets/image/coin.png') : require('../assets/image/coin2.png')"
                            style="width: 30px; height: 30px; display: block;" />
                        <span>{{ item.coin }}</span>
                        <span class="listen-seconds" :class="{ 'active-item': item.status === '2' }">{{ item.key
                            }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';


export default {
    components: {

    },
    mounted() {

    },
    name: "Welfare",
    data() {
        return {
            wealBean: {
                coin: 0,
                today_read_time: 0,
                year: '',
                m: '',
                d: '',
                w: '',
                sign_total_coin: 0,
                watch_video_coin: '',
                watch_video_times: '',
                interact_config: [],
                sign_config: [{
                    id: 0,
                    title: "",
                    coin: 0,
                    desc: "",
                    created_at: "",
                    updated_at: "",
                    is_type: 0
                },],
                daily_read_config: [{
                    id: 0,
                    key: "",
                    coin: 0,
                    delete_time: null,
                    created_at: "",
                    updated_at: "",
                    time: 0,
                    status: "",
                },],
            },
            user_id: this.$route.query.uid,
            isRefreshing: false
        };
    },
    created() {
        this.loadData();
    },
    computed: {
        todayCoin() {
            return this.wealBean.sign_config.find(bean => bean.title === this.wealBean.w)?.coin || 0;
        },
        tomorrowCoin() {
            const tomorrowIndex = (this.wealBean.sign_config.indexOf(this.wealBean.sign_config.find(bean => bean.title === this.wealBean.w)) + 1) % 7; // 获取明天的索引（0-6）
            const tomorrowItem = this.wealBean.sign_config[tomorrowIndex];
            return tomorrowItem?.coin || 0;
        },
        countIsTypeOne() {
            return this.wealBean.sign_config.filter(bean => bean.is_type === 1).length;
        },
        calculateReadTime() {
            let result = '';
            let todayReadTime = this.wealBean.today_read_time;
            let totalSecond = 0;
            let list = this.wealBean.daily_read_config;

            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                totalSecond += item.time;
                if (todayReadTime > totalSecond) {
                    if (i === this.list.length - 1) {
                        result = '今天已领取全部金币';
                    } else {
                        continue;
                    }
                } else {
                    result = `在听${item.key}，可以领取${item.coin}金币`;
                    break;
                }
            }

            return result;
        }

    },
    methods: {
        onRefresh() {
            this.isRefreshing = true;
            this.user_id = Android.getUid();
            this.loadData();
        },
        loadData() {
            console.log('userid:' + this.user_id);
            this.$axios.get('/api/welfare/base_info', {
                params: {
                    user_id: this.user_id,
                }
            }).then(res => {
                if (Android && typeof Android.showToast === 'function') {
                    Android.showToast('正在加载数据,请稍后!');
                }
                console.log(JSON.stringify(res.data.data));
                this.wealBean.coin = res.data.data.coin;
                this.wealBean.today_read_time = res.data.data.today_read_time;
                this.wealBean.year = res.data.data.year;
                this.wealBean.m = res.data.data.m;
                this.wealBean.d = res.data.data.d;
                this.wealBean.w = res.data.data.w;
                this.wealBean.sign_total_coin = res.data.data.sign_total_coin;
                this.wealBean.watch_video_coin = res.data.data.watch_video_coin;
                this.wealBean.watch_video_times = res.data.data.watch_video_times;
                this.wealBean.interact_config = res.data.data.interact_config;
                this.wealBean.sign_config = res.data.data.sign_config;
                this.wealBean.daily_read_config = res.data.data.daily_read_config;
            })
        },
        handleCash() {
            console.log('去提现' + Android);
            if (Android) {
                Android.withdraw();
            }
        },
        handleDetail() {
            console.log(this.todayCoin)
            //console.log(todayCoin())
            if (Android) {
                //去签到
                Android.toSign(this.todayCoin, this.wealBean.watch_video_times, this.wealBean.watch_video_coin);
            }

        },

        handleGetCoin(status, coin, id) {
            console.log(`点击领取${status}`);
            if (Android && status == "1") {
                //去签到
                Android.readReward(id, coin, this.wealBean.watch_video_times, this.wealBean.watch_video_coin);
            }
        },

        handleActivity() {
            //this.$toast(this.wealBean.watch_video_coin);
            //看视频
            if (Android) {
                Android.showVideo(this.wealBean.watch_video_coin, this.wealBean.watch_video_times);
            }
            console.log('参与活动');
        },

        handleToListen() {
            if (Android) {
                //去听书
                Android.toListenBook();
            }
            console.log('去听书');
        },
        setVideoTimes(times) {
            this.wealBean.watch_video_times = times;
            console.log(times);
        },
        refreshPage() {

        }
    }
}

</script>
<style lang='less' scoped>
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: auto;
    /* 确保页面可以滚动 */
}

.scrollable-content {
    position: relative;
    min-height: 100vh;
    /* 确保容器至少填满视口 */
    overflow: auto;
    /* 确保页面可以滚动 */
    -webkit-overflow-scrolling: touch;
    /* 让滚动更流畅 */
    background: linear-gradient(to bottom, #fc8928, #fbd79c);
}

* {
    margin: 0;
    padding: 0;
}

button {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.content {
    /* background: linear-gradient(to bottom, #fc8928, #fbd79c); */
    min-height: 100vh;
    width: 100%;
    padding-bottom: 200px;
}

.header-content {
    /* background: linear-gradient(to bottom, #fc8928, #fbd79c); */
    padding-left: 20px;
    padding-top: 120px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.income-statistic {
    color: #faf4da;
    background: rgba(251, 215, 156, .65);
    border-radius: 40px;
    padding: 30px;
    border: 1px solid #fbd580;
}

.income-statistic-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desc {
    font-size: 16px;
    margin-top: 20px;
    display: none;
    color: #f89a39;
}

/* .income-statistic-wrapper-item .title { */
/* color: #fee5bd; */
/* } */

.income-statistic-wrapper-item .money {
    font-size: 60px;
    margin-right: 2px;
    color: #FFF;
}

.title {
    font-size: 30px;
}

.border-right {
    border-right: 1px solid #f1b66d;
    padding-right: 20px;
}

.right-btn {
    height: 50px;
    line-height: 30px;
    font-size: 20px;
    border-radius: 25px;
    width: 120px;
    margin-top: 10px;
    color: #f89a39;
    margin-right: 10px;
    font-weight: bold;
    border: none;
}

.right-btn:after {
    display: none;
}

.sign-wrapper {
    background: #fbe7a9;
    border-radius: 40px;
    padding: 30px;
    margin-top: 20px;
}

.sign-wrapper-top {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0 5px;
    margin-bottom: 15px;
}

.sign-wrapper-top-left {
    flex: 1;
}

.sign-wrapper-top-left .day-content {
    font-size: 40px;
    font-weight: bold;
    color: #f89a39;
}

.day-num {
    font-size: 60px;
}

.next-day-content {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #f89a39;
}

.view-detail-btn {
    height: 50px;
    font-size: 20px;
    border-radius: 20px;
    background: #FED181;
    color: white;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    width: 120px;
}

.view-detail-btn::after {
    display: none;
}

.sign-wrapper-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.sign-wrapper-bottom-item {
    display: flex;
    background: #FFFCF4;
    justify-content: space-between;
    padding: 20px;
    border-radius: 15px;
    color: #dbdbdb;
}

.sign-wrapper-bottom-item-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-wrapper-bottom-item-left .coins {
    font-size: 20px;
    font-weight: bold;
    padding-top: 5px;
    text-align: center;
}

.sign-wrapper-bottom-item.active-item {
    background: #f8c97c;
    /* 白色背景 */
    color: white;
}

.sign-wrapper-bottom-item-right {
    padding-left: 5px;
    padding-top: 10px;
}

.sign-wrapper-bottom-item-right .time {
    font-size: 20px;
    height: 20px;
    line-height: 20px;
}

.sign-wrapper-bottom-item-right .text {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.sign-wrapper-bottom-item-right .get-btn {
    background: #fc8928;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-radius: 20px;
    width: 85px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
}

.activity-wrapper {
    padding: 20px 20px;
    //display: none;
}

.activity-wrapper-content {
    background: #feeed4;
    border-radius: 40px;
    overflow: hidden;
    padding: 30px;
    border: 1px solid #FFF;
    text-align: center;
}

.activity-title {
    font-size: 40px;
    font-weight: bold;
    color: #a0652d;
    margin-bottom: 40px;
}

.activity-today-content {
    color: #f89a39;
    font-size: 20px;
    margin-bottom: 20px;
}

.activity-today-content .total-num {
    font-size: 60px;
    color: #e55132;
    font-weight: bold;
}

.activity-btn {
    padding: 25px;
}

.activity-btn button {
    background: linear-gradient(to right, #e55132, #f89a39);
    border-radius: 30px;
    color: #FFFCF4;
    font-weight: bold;
    letter-spacing: 2px;
    width: 100%;
    height: 80px;
    box-shadow: 0 0 20px #f89a39;
}

.activity-btn button::after {
    border: 0;
}

.listen-wrapper {
    background: #FFF;
    margin: 20px;
    border-radius: 40px;
    padding: 26px;
}

.listen-wrapper-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listen-wrapper-top-left {
    flex: 1;
}

.listen-wrapper-top-des {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.listen-wrapper-top-des .tip {
    font-size: 16px;
    color: #f89a39;
    font-weight: normal;
}

.today-listen {
    color: #999;
    font-size: 24px;
    margin-top: 14px;
}

.listen-wrapper-top button {
    height: 50px;
    font-size: 20px;
    border-radius: 20px;
    background: #FFEEDE;
    color: #EEA335;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    width: 120px;
}

.listen-wrapper-top button::after {
    display: none;
}

.listen-wrapper-center {
    margin: 10px 0;
    font-size: 18px;
    background: #f6f6f6;
    padding: 15px;
    border-radius: 10px;
    color: #f89a39;
}

.listen-list {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    /* 添加这一行确保项目在容器宽度不够时换行 */
}

.listen-list-ite-temp {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f6f6;
    color: #f89a39;
    padding: 5px 0;
    margin-bottom: 5px;
    border-radius: 15px;
    width: calc(20%);
    font-size: 20px;
    box-sizing: border-box;
    gap: 5px;
}

.listen-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f6f6;
    color: #f89a39;
    padding: 5px 0;
    margin: 5px;
    border-radius: 15px;
    width: calc(17%);
    font-size: 22px;
    box-sizing: border-box;
    gap: 10px;
}

.listen-list-item.active-item {
    background: #f8c97c;
    /* 白色背景 */
    color: white;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
    margin: 5px;
    border-radius: 15px;
    width: calc(18%);
    font-size: 22px;
    box-sizing: border-box;
    gap: 10px;
}

.listen-seconds {
    color: #999;
    padding: 10px;
}

.listen-seconds.active-item {
    color: #7d7a7a;
    padding: 10px;
}

.munites {
    color: #000;
    padding: 0 3px;
    font-weight: bold;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px;
    background-color: #f5f5f5;
    padding: 10px;
}

.doge {
    width: 140px;
    height: 72px;
    margin-top: 8px;
    border-radius: 4px;
}
</style>
