<template>
    <div id="service">
        <div class="service-head">
            <div class="service-head-top">
                <img src="../assets/serverImage/contact_service_tit@2x.png" alt="" class="top-icon">
                <p class="top-title">联系方式</p>
            </div>
            <div class="service-head-bottom">
                您可以拨打客服联系电话，也可以联系客服微信QQ，即可在线沟通客服
            </div>
        </div>
        <div class="service-box">
            <div class="service-box-item">
                <div class="service-box-item-left">
                    <img src="../assets/serverImage/contact_way1@2x.png" alt="" class="phone-icon">
                    <p class="server-text">{{mobile}}</p>
                </div>
                <div :class="copyPhoneStatus? 'service-box-item-right copy':'service-box-item-right already-copy'"
                     @click="copyPhone" id="server-phone" :data-clipboard-text="mobile">
                    {{copyPhoneStatus?'复制':'已复制'}}
                </div>
            </div>
            <div class="service-box-item">
                <div class="service-box-item-left">
                    <img src="../assets/serverImage/contact_way2@2x.png" alt="" class="wx-icon">
                    <p class="server-text">{{weixin}}</p>
                </div>
                <div :class="copyWxStatus? 'service-box-item-right copy':'service-box-item-right already-copy'"
                     @click="copyWx" id="server-wx" :data-clipboard-text="weixin">
                    {{copyWxStatus?'复制':'已复制'}}
                </div>
            </div>
            <div class="service-box-item">
                <div class="service-box-item-left">
                    <img src="../assets/serverImage/contact_way3@2x.png" alt="" class="qq-icon">
                    <p class="server-text">{{qq}}</p>
                </div>
                <div :class="copyQQStatus? 'service-box-item-right copy':'service-box-item-right already-copy'"
                     @click="copyQQ" id="server-qq" :data-clipboard-text="qq">
                    {{copyQQStatus?'复制':'已复制'}}
                </div>
            </div>
        </div>
        <div class="problem">
            <h5 class="problem-title">常见问题</h5>
            <div class="problem-class">
                <div :class="classIndex==index?'problem-class-item active':'problem-class-item'"
                     v-for="(item,index) in classData" :key="index" @click="chooseClass(index)">{{item}}
                </div>
            </div>
            <div class="problem-box">
                <div class="problem-box-item" v-for="(item,index) in problemData" :key="index">
                    <div class="item-top" @click="chooseProblem(index)">
                        <h5 class="problem-box-item-title">{{item.title}}</h5>
                        <img :src="item.iconStatus?require('../assets/serverImage/common_qu_coll@2x.png'):require('../assets/serverImage/common_qu_expand@2x.png')"
                             alt="" class="item-icon">
                    </div>
                    <div class="item-bottom" v-if="item.iconStatus">{{item.explain}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Clipboard from 'clipboard';

    export default {
        name: "rule",
        data() {
            return {
                mobile: '',
                qq: '',
                weixin: '',
                copyPhoneStatus: true,
                copyWxStatus: true,
                copyQQStatus: true,
                classData: [],
                problemData: [],
                classIndex: 0,
                allData: [],
            }
        },
        created() {
            this.$axios({
                url: '/h5/contact',
                method: 'get',
                params: {}
            }).then((res) => {
                let data = res.data.data;
                this.mobile = data.mobile;
                this.qq = data.qq;
                this.weixin = data.weixin;
                this.allData = data.common_problem;
                let classArr = [];
                this.allData.map((num) => {
                    classArr.push(num.class)
                });
                for (var i = 0, len = classArr.length; i < len; i++) {
                    if (this.classData.indexOf(classArr[i]) === -1) {
                        this.classData.push(classArr[i]);
                    }
                }
                this.dispose();
            }).catch((err) => {
                alert(err.message)
            });
        },
        methods: {
            copyPhone() {
                var clipboard = new Clipboard('#server-phone');
                clipboard.on('success', e => {
                    this.copyPhoneStatus = false;
                    clipboard.destroy()
                });
                clipboard.on('error', e => {
                    // 不支持复制
                    console.log('该浏览器不支持自动复制');
                    // 释放内存
                    clipboard.destroy()
                })
            },
            copyWx() {
                var clipboard = new Clipboard('#server-wx');
                clipboard.on('success', e => {
                    this.copyWxStatus = false;
                    clipboard.destroy()
                });
                clipboard.on('error', e => {
                    // 不支持复制
                    console.log('该浏览器不支持自动复制');
                    // 释放内存
                    clipboard.destroy()
                })
            },
            copyQQ() {
                var clipboard = new Clipboard('#server-qq');
                clipboard.on('success', e => {
                    this.copyQQStatus = false;
                    clipboard.destroy()
                });
                clipboard.on('error', e => {
                    // 不支持复制
                    console.log('该浏览器不支持自动复制');
                    // 释放内存
                    clipboard.destroy()
                })
            },
            chooseClass(index) {
                this.classIndex = index;
                this.dispose();
                this.$forceUpdate()
            },
            chooseProblem(index) {
                this.problemData[index].iconStatus = !this.problemData[index].iconStatus
                this.$forceUpdate()
            },
            dispose() {
                this.problemData = [];
                this.allData.map((num, index) => {
                    if (num.class == this.classData[this.classIndex]) {
                        num.iconStatus = false;
                        this.problemData.push(num)
                    }
                })
                this.problemData.map((num, index) => {
                    if (index === 0) {
                        num.iconStatus = true;
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    #service {
        .service-head {
            width: 750px;
            height: 250px;
            background: url("../assets/serverImage/contact_service_bg.png");
            background-size: 100% 100%;
            padding: 40px;
            box-sizing: border-box;

            .service-head-top {
                display: flex;
                align-items: center;

                .top-icon {
                    width: 32px;
                    height: 32px;
                }

                .top-title {
                    font-size: 30px;
                    font-family: PingFang SC;
                    color: rgba(255, 255, 255, 1);
                    text-indent: 14px;
                }
            }

            .service-head-bottom {
                padding-top: 25px;
                font-size: 28px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }
        }

        .service-box {
            width: 670px;
            height: 330px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 46px 0px rgba(132, 134, 156, 0.1);
            border-radius: 20px;
            margin-left: 50%;
            transform: translateX(-50%) translateY(-55px);
            padding: 40px 50px;
            box-sizing: border-box;

            .service-box-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;

                .service-box-item-left {
                    display: flex;
                    align-items: center;

                    .phone-icon {
                        width: 26px;
                        height: 28px;
                    }

                    .wx-icon {
                        width: 33px;
                        height: 27px;
                    }

                    .qq-icon {
                        width: 28px;
                        height: 32px;
                    }

                    .server-text {
                        font-size: 32px;
                        font-family: PingFang SC;
                        color: rgba(10, 28, 51, 1);
                        text-indent: 20px;
                    }
                }

                .service-box-item-right {
                    width: 144px;
                    height: 60px;
                    border-radius: 30px;
                    font-size: 28px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 60px;
                    text-align: center;
                }

                .copy {
                    background: rgba(77, 119, 253, 1);
                }

                .already-copy {
                    background: rgba(191, 194, 204, 1);
                }
            }
        }

        .problem {
            border-top: 20px solid #F2F5F8;
            padding: 40px;
            box-sizing: border-box;

            .problem-title {
                font-size: 32px;
                font-family: PingFang SC;
                font-weight: bold;
                color: rgba(10, 28, 51, 1);
            }

            .problem-class {
                display: flex;
                flex-wrap: wrap;
                padding: 30px 0;

                .problem-class-item {
                    width: 120px;
                    height: 50px;
                    background: rgba(242, 245, 248, 1);
                    border-radius: 4px;
                    text-align: center;
                    font-size: 28px;
                    line-height: 50px;
                    margin-right: 46px;
                    border: 1px solid rgba(242, 245, 248, 1);
                    box-sizing: border-box;
                }

                .problem-class-item.active {
                    color: rgba(77, 119, 253, 1);
                    background: rgba(240, 243, 252, 1);
                    border: 1px solid rgba(77, 119, 253, 1);
                }
            }

            .problem-box {
                .problem-box-item {
                    margin-bottom: 30px;

                    .item-top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .problem-box-item-title {
                            font-size: 28px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: rgba(96, 98, 102, 1);
                        }

                        .item-icon {
                            width: 30px;
                            height: 16px;
                        }
                    }

                    .item-bottom {
                        background: rgba(242, 245, 248, 1);
                        border-radius: 10px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(144, 147, 153, 1);
                        padding: 35px 45px;
                        box-sizing: border-box;
                        line-height: 42px;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
</style>