<template>
    <div class="sdk-info-list">
        <div class="title">第三方信息共享清单</div>
        <van-cell-group>
            <van-row class="table-header">
                <van-col span="4">SDK名称</van-col>
                <van-col span="4">公司名称</van-col>
                <van-col span="4">使用场景/目的</van-col>
                <van-col span="4">获取的信息</van-col>
                <van-col span="4">共享方式</van-col>
                <van-col span="4">隐私协议</van-col>
            </van-row>
            <van-row v-for="(sdk, index) in sdkList" :key="index" class="table-row">
                <van-col span="4">{{ sdk.name }}</van-col>
                <van-col span="4">{{ sdk.company }}</van-col>
                <van-col span="4">{{ sdk.purpose }}</van-col>
                <van-col span="4">{{ sdk.data }}</van-col>
                <van-col span="4">{{ sdk.sharingMethod }}</van-col>
                <van-col span="4">
                    <a :href="sdk.privacyPolicy" target="_blank">查看</a>
                </van-col>
            </van-row>
        </van-cell-group>
        
        <div class="end">
            &nbsp;&nbsp;&nbsp;&nbsp;其他合作方目录：</br>
            </br>
            &nbsp;&nbsp;&nbsp;&nbsp;为向您推送广告信息，我们会向北京爱奇艺科技有限公司共享您的IP地址、IDFA、设备型号、系统版本、运营商信息及网络信息；
            向科大讯飞股份有限公司共享您的IP地址、IDFA、操作系统、设备厂商、运营商信息及网络信息。</br>
            </br>
            &nbsp;&nbsp;&nbsp;&nbsp;为实现广告监测，我们会和秒针信息技术有限公司共享您的IP地址、操作系统、IDFA。</br>
            </br>
            &nbsp;&nbsp;&nbsp;&nbsp;为优化广告投放效果，我们会和北京深演智能科技股份有限公司加密共享您的IDFA。

        </div>
    </div>
</template>

<script>
export default {
    name: "SDKInfoList",
    data() {
        return {
            sdkList: [
                {
                    name: '穿山甲SDK--字节跳动',
                    company: '北京巨量引擎网络技术有限公司',
                    purpose: '广告投放',
                    data: '设备信息：设备品牌、型号、软件系统版本、分辨率、网络信号强度、设备语言、传感器信息基础信息，手机系统重启时间、磁盘总空间、系统总内存空间、IP地址、设备标识符（IDFV、具体字段因软硬件版本不同存在差异）。应用信息：开发者应用名、应用包名、版本号、应用前后台状态、运行中的任务进程（仅获取七猫免费小说相关进程）。广告信息：对广告的展示、点击、转化、交互数据。其他信息：运营商信息、设备时区。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://partner.oceanengine.com/privacy'
                },
                {
                    name: '优量汇SDK--腾讯',
                    company: '深圳市腾讯计算机系统有限公司',
                    purpose: '广告投放',
                    data: '设备信息：设备制造商、设备品牌、设备型号、操作系统版本、屏幕分辨率、屏幕方向、屏幕DPI、IP地址、时区、网络类型、运营商、设备名称、系统启动时间、系统语言、磁盘空间、设备姿态、压力、加速度传感器等基础信息；标识符：IDFV、IDFA；应用信息：宿主应用的包名、版本号、运行中的任务进程（仅获取七猫免费小说相关进程）、宿主应用运行状态；使用数据：产品交互数据、广告数据（展示、点击、转化广告数据）。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://e.qq.com/dev/help_detail.html?cid=2005&pid=5983'
                },
                {
                    name: '百度网盟SDK（百度移动推广SDK）',
                    company: '北京百度网讯科技有限公司',
                    purpose: '广告投放',
                    data: '设备信息（最终用户的硬件型号、操作系统版本、设备配置、IDFA、操作记录、点击、展示、转化等交互数据',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://union.baidu.com/bqt#/policies'
                }, {
                    name: '快手SDK',
                    company: '北京快手广告有限公司',
                    purpose: '广告投放',
                    data: '设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息、IP地址、WIFI状态、传感器信息（包括加速度传感器、重力传感器、线性加速度传感器和陀螺仪传感器，单纯的传感器数据不属于个人信息）、SIM卡激活信息。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://u.kuaishou.com/home/help/detail/1337/1400/1406'
                },

                {
                    name: '腾讯Bugly SDK',
                    company: '深圳市腾讯计算机系统有限公司',
                    purpose: '排查崩溃问题,帮助APP提升稳定性',
                    data: 'Android Id、设备型号、操作系统名称及版本、操作系统内部版本号、网络状态、cpu属性、内存信息、磁盘使用量信息、运行时手机状态(进程所占内存、虚拟内存)、IDFV、是否越狱、地区编码。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://privacy.qq.com/document/preview/693de0d2b30f4c9db6dfbcbfd6240e37'
                }, {
                    name: '友盟SDK（包含友盟推送SDK、友盟移动统计SDK）',
                    company: '友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司',
                    purpose: '统计、推送',
                    data: 'IDFA、GAID、IP、网络运营商信息、应用包名。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ'
                }, {
                    name: '阿里云 HTTPDNS SDK',
                    company: '阿里云计算有限公司',
                    purpose: '域名解析',
                    data: '运营商信息，网络信息',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.11186623.0.0.9ae430e5UFREU1'
                }, {
                    name: '数字联盟可信ID SDK',
                    company: '北京数字联盟网络科技有限公司',
                    purpose: '检测设备欺诈与作弊行为，识别反馈设备的真实性',
                    data: '设备制造商、设备型号、设备系统版本、应用版本、传感器、陀螺仪、IDFA(针对儿童类应用，不收集IDFA)、IDFV、设备网络状况信息及其他设备物理环境信息、设备IP。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://www.shuzilm.cn/main/privacy.html'
                }, {
                    name: '数美SDK',
                    company: '北京数美时代科技有限公司',
                    purpose: '用于风险控制与反欺诈，以保障帐户和交易安全',
                    data: '设备基本信息，包括设备品牌、设备制造商、设备型号、设备名称、设备系统类型及版本信息、设备基本配置、设备基本设置、设备环境、传感器信息（磁场、陀螺仪、加速度传感器）；设备标识信息，包括IDFV、IDFA；网络信息，包括网络的接入形式、IP地址、网络运营商信息、网络连接状态；设备应用信息，包括SDK宿主应用包名（仅获取七猫免费小说包名）及版本号、运行中的任务进程（仅获取七猫免费小说相关进程）、读取/写入外部存储文件。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://www.ishumei.com/legal/cn/privacy.html'
                },
                {
                    name: '微信SDK（微信/腾讯开放平台 SDK）',
                    company: '深圳市腾讯计算机系统有限公司',
                    purpose: '微信登录',
                    data: '用户在第三方应用中主动选择的图片或内容、用户主动选择的微信头像、昵称信息、用户发起支付时第三方应用生成的微信支付订单标识。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8?social_platform=link&p1=2_22_221'
                },
                {
                    name: '移动一键登录SDK（移动开发平台SDK）',
                    company: '中移互联网公司',
                    purpose: '	一键登录',
                    data: '移动：网络类型、网络地址、运营商类型、本机号码信息、手机设备类型、手机操作系统、硬件厂商。电信：网络IP地址、网络类型。联通：网络类型、网络地址、运营商类型、本机手机号、手机设备类型、手机操作系统、硬件厂商。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://wap.cmpassport.com/resources/html/contract.html'
                },
                {
                    name: '百度语音合成SDK（百度语音识别SDK）',
                    company: '北京百度网讯科技有限公司',
                    purpose: '语音朗读',
                    data: '设备信息：WIFI状态、设备型号、操作系统。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://ai.baidu.com/ai-doc/REFERENCE/Jkdyl0v3v'
                },
                {
                    name: '京媒SDK',
                    company: '北京京东叁佰陆拾度电子商务有限公司',
                    purpose: '用于风险控制与反欺诈，以保障帐户和交易安全',
                    data: '设备信息：设备制造商、设备型号、设备名称、设备工业名称、设备品牌、设备网络码、系统版本、SIM卡状态、传感器基础设备信息、系统指纹、SD卡状态信息、IP地址、网络信息、设备标识符（IDFA）；应用信息：广告信息：对广告的展示、点击及转化交互数据；其他信息：运营商信息。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://opendoc.jd.com/janGroup/help/agreement/privacy.html'
                },
                {
                    name: '神策 SDK',
                    company: '神策网络科技（北京）有限公司',
                    purpose: '用户识别、用户行为分析',
                    data: '设备信息：设备属性信息（UUID、IDFA、IDFV） 设备连接信息（浏览器类型、电信运营商、设备品牌）；日志信息：终端用户对集成神策 SDK 的产品和/或服务的使用情况、IP地址、所访问服务的URL、浏览器类型和使用的语言、访问服务的时间；应用唯一标识、应用名称和应用版本号。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_privacy_policy-22255999.html#id-'
                },
                {
                    name: '秒验SDK',
                    company: '识别用户的手机号码快速登录',
                    purpose: '识别用户的手机号码快速登录',
                    data: '系统运行信息、网络状态信息、iOS广告标识符（IDFA）、运营商信息、手机号码（服务端非明文存储）。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'http://www.mob.com/about/policy'
                },
                {
                    name: '巨量引擎激活SDK',
                    company: '北京巨量引擎网络技术有限公司',
                    purpose: '提供数据统计和分析服务',
                    data: 'IPV4、IPV6、UA、本地时间、IDFV、IDFA、系统更新时间、设备名称、设备machine (device model)、Hardware model、系统启动时间(秒)、系统版本、物理内存大小、硬盘大小、硬盘挂载id、设备初始化时间、tun、anpi、应用内用户唯一ID、开发者应用名、应用包名、运行中的进程信息、应用版本号、SDK版本号、应用前后台状态',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://open.oceanengine.com/labels/8/docs/1708428054592516'
                },
                {
                    name: 'Tanx SDK',
                    company: '杭州易宏广告有限公司',
                    purpose: '广告投放，广告归因，广告监测，反作弊。',
                    data: 'IDFV、IDFA、设备类型，设备型号，手机制造商，手机品牌，操作系统，操作系统版本，分辨率，当前网络接入类型，网络运营商，设备密度，屏幕方向、设备基础信息，如设备启动时间、设备语言、设备名称、设备内存和硬盘空间、系统更新时间、设备时区、版本号，应用包名，加速传感器信息、应用信息、广告曝光、点击、互动行为信息。',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://terms.alicdn.com/legal-agreement/terms/suit_bu1_ali_mama_division/suit_bu1_ali_mama_division202202171548_47251.html?spm=a2e1xn.27152719.c4bf85e4b.15.2cfc52aa8SYFyp'
                },
                {
                    name: '微博 SDK',
                    company: '北京微梦创科网络技术有限公司',
                    purpose: '向用户提供分享能力',
                    data: '设备信息（IMEI、Android ID、MAC、OAID、IMSI及其他设备相关信息）、网络信息、存储信息',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://weibo.com/signup/v5/privacy#content'
                },
                {
                    name: '钉钉 SDK',
                    company: '钉钉（中国）信息技术有限公司、钉钉科技有限公司',
                    purpose: '提供即时沟通服务',
                    data: '设备信息（BSSID\SSID、设备序列码、硬件序列号）',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://page.dingtalk.com/wow/dingtalk/act/privacypolicycn?wh_biz=tm'
                },
                {
                    name: '极光推送',
                    company: '深圳市和讯华谷信息技术有限公司',
                    purpose: '用于实现消息推送(或其他推送)功能',
                    data: '设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM 卡/IMSI 信息等）',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://www.jiguang.cn/license/privacy'
                },
                {
                    name: '七牛 SDK',
                    company: '深圳市和讯华谷信息技术有限公司',
                    purpose: '图片上传下载',
                    data: '设备相关信息（包括设备名称、设备型号、设备设置、识标符）、日志信息',
                    sharingMethod: 'SDK自采集',
                    privacyPolicy: 'https://www.qiniu.com/privacy-right?ref=developer.qiniu.com'
                }
            ]
        };
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        }
    }
};
</script>

<style scoped>
.title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
}

.sdk-info-list {
    padding: 20px;
}

.table-header {
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    background-color: #f7f8fa;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-row {
    font-size: 10px;
    padding: 10px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e4e7ed; 
}

.table-row a {
    color: #409eff;
}

.table-row .van-col {
    flex: 1;
}

.table-row .van-col:nth-child(4) {
    flex: 3;
}

.end {
    font-size: 10px;
}
</style>