<template>
    <div>
        <van-nav-bar placeholder fixed left-arrow :border='false' title="活动规则" z-index="99" class="header"
            @click-left="$router.back()">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33" />
            </template>
        </van-nav-bar>
        <div class="Rules">
            <p class="title"></p>
            <div>
                <p>1.邀请人A可从一级好友B（B注册一年内）和二级好友C的年化出借额中获得不同合伙人级别对应返现比例的奖励。若一级好友B注册超过一年，则邀请人A的两级奖励停止发放。邀请人需完成银行存管账户开户方可收到返现奖励，奖励（保留两位小数）可直接提现。
                </p>
                <p><br></p>
                <p>2.返现奖励逐月发放，散标将在好友还款日当日发放，智选服务在好友进入服务期限的次月当日发放，发放当日计算邀请人合伙人级别。</p>
                <p><br></p>
                <p>3.好友发生转投的踏实赚邀友奖励按照原有计划发放，直至结束为止。</p>
                <p><br></p>
                <p>4.邀请人A可从一级好友B（B注册一年内）和二级好友C的年化出借额中获得不同合伙人级别对应返现比例的奖励。若一级好友B注册超过一年，则邀请人A的两级奖励停止发放。邀请人需完成银行存管账户开户方可收到返现奖励，奖励（保留两位小数）可直接提现。
                </p>
                <p><br></p>
                <p>5.邀请人A可从一级好友B（B注册一年内）和二级好友C的年化出借额中获得不同合伙人级别对应返现比例的奖励。若一级好友B注册超过一年，则邀请人A的两级奖励停止发放。邀请人需完成银行存管账户开户方可收到返现奖励，奖励（保留两位小数）可直接提现。若一级好友B注册超过一年，则邀请人A的两级奖励停止发放。邀请人需完成银行存管账户开户方可收到返现奖励，奖励（保留两位小数）可直接提现若一级好友B注册超过一年，则邀请人A的两级奖励停止发放。邀请人需完成银行存管账户开户方可收到返现奖励，奖励（保留两位小数）可直接提现
                </p>
                <p><br></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Rules",
    data() {
        return {
            markList: [],
        }
    },
    created() {
        this.getList()
    },
    methods: {
      
    },
}
</script>

<style scoped>
.title {
    margin-bottom: 40px;
    text-align: center;
}

.Rules {
    padding: 40px;
    text-align: justify;
    line-height: 40px;
    font-size: 26px;
    color: #606266;
    margin-bottom: 100px;
}
</style>