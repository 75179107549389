<template>
    <div class="Share">
        <div class="Share_top">
            <img src="../assets/image/share_arrows@2x.png" alt="" class="share_arrows">
            <div class="Share-text">
                <p>点击右上角“...”</p>
                <p>分享赚书豆</p>
            </div>
        </div>
        <div class="Share_button">
            <p class="Share_title">海量全网热门正版书籍</p>
            <p class="Share_text">
                书友力荐
                <span>随时随地 想看就看</span>
            </p>
            <p class="Share_btn">更多内容等你体验</p>
            <img src="../assets/image/share_img@2x.png" alt="" class="share_img">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Share"
    }
</script>

<style scoped>
    .Share_btn {
        color: #fff;
        font-size: 36px;
        background: #4D77FD;
        box-shadow: 0px 6px 27px 0px rgba(77, 119, 253, 0.24);
        border-radius: 25px;
        padding: 10px 25px;
    }

    .Share_button {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Share_text > span {
        color: #4D77FD;
        font-size: 48px;
        font-weight: bold;
    }

    .Share_text {
        color: #fff;
        font-size: 36px;
        margin: 23px 0 44px 0;
    }

    .Share_title {
        color: #fff;
        font-size: 48px;
        margin-top: 120px;
    }

    .Share_top {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 30px;
    }


    .Share-text > p:last-child {
        text-align: left;
    }

    .Share-text {
        margin-top: 30px;
        font-size: 48px;
        font-weight: bold;
        color: #fff;
    }

    .Share-text > p {
        margin-bottom: 15px;
    }

    .share_arrows {
        width: 107px;
        height: 170px;
        margin: 40px 40px 0 0;
    }

    .share_img {
        height: 463px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>