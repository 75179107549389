<template>
    <div id="agreement">
        <h5 class="title">{{title}}</h5>
        <div v-html="content" class="text-box"></div>
    </div>
</template>

<script>
    export default {
        name: "agreement",
        data() {
            return {
                title: '',
                content: '',
            }
        },
        created() {
            this.$axios({
                url: '/illustration/index',
                method: 'get',
                params: {
                    mark: 'userterms'
                }
            }).then((res) => {
                let data = res.data.data;
                this.content = data.content;
                this.title = data.title;
            }).catch((err) => {
                alert(err.message)
            });
        }
    }
</script>

<style scoped lang="less">
    #agreement {
        padding: 40px;
        box-sizing: border-box;

        .title {
            font-size: 30px;
            text-align: center;
            line-height: 60px;
        }

        .text-box {
            font-size: 26px;
            color: #606266;
        }
    }

</style>