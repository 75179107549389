import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import service from '../views/service'
import agreement from '../views/agreement'
import userterms from '../views/userterms'
import myfirend from '../views/myfirend'
import share from '../views/share'
import invitation from '../views/Invitation'
import welfare from '../views/Welfare'
import rules from '../components/Rules'
import invitDeatil from '../views/InvitDeatil.vue'
import SdkInfoList from '../views/SdkInfoList.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/myfirend',
        component: Home
    }, {
        path: '/service',
        name: 'Service',
        component: service
    }, {
        path: '/agreement',
        name: 'Agreement',
        component: agreement
    },{
        path:'/userterms',
        name: 'UserTerms',
        component: userterms
    },{
        path:'/myfirend',
        name: 'MyFriend',
        component: myfirend
    },{
        path:'/share',
        name: 'Share',
        component: share
    },{
        path: '/invitation',
        name: 'Invitation',//邀请好友
        component: invitation,
        meta: {
            keepAlive: true ,// 需要被缓存
        },
    },{
        path: '/welfare',
        name: 'Welfare',//福利页
        component: welfare
    },{
        path: '/rules',
        name: 'Rules',
        component: rules
    },
    {
        path: '/invitDeatil',
        name: 'InvitDeatil',
        component: invitDeatil,
        meta: {
            keepAlive: false ,// 需要被缓存
        },
    },
    {
        path: '/sdkInfoList',
        name: 'SdkInfoList',
        component: SdkInfoList,
        meta: {
            keepAlive: false ,// 需要被缓存
        },
    },
    {path: '*', redirect: '/',}
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
