<template>
    <div id="home" :class="modalShow?'modalShow':''" @click="closeInvite">
        <img src="../assets/image/bg.png" alt="" class="bg-img">
        <div class="rule" @click.stop="lookRule">查看规则</div>
        <div class="invite1-box">
            <h5 class="invite1-hint">邀好友送书豆啦</h5>
            <h5 class="invite1-text">书豆上不封顶</h5>
            <span class="invite1-explain">被邀请的好友需注册后才可获得相应的奖励</span>
            <div class="invite1-button" @click.stop="inviteApp">立即邀请</div>
        </div>
        <div class="invitation_reward">
            <span class="invitation-title">邀请奖励</span>
            <div class="invitation_reward-box">
                <div class="invitation_reward-box-item" v-for="(item,index) in inviteData" :key="index">
                    <div class="invitation_reward-box-item-top">
                        <p class="invitation_reward-price"><span class="invitation_reward-number">{{item.coin}}</span>书豆
                        </p>
                        <p class="invitation_reward-day">第{{index==0?'一':index==1?'二':'三'}}天</p>
                    </div>
                    <div class="invitation_reward-box-item-bottom">好友阅读{{item.read_time}}分钟</div>
                </div>
            </div>
        </div>
        <div class="invitation_process">
            <span class="invitation-title">邀请流程</span>
            <div class="invitation_process-bottom">
                <div class="invitation_process-bottom-item">
                    <p>发送邀请链接</p>
                    <p>或二维码</p>
                </div>
                <div class="invitation_process-bottom-item">
                    <p>好友下载并</p>
                    <p>注册APP</p>
                </div>
                <div class="invitation_process-bottom-item">
                    <p>好友注册成功</p>
                    <p>你赚书豆</p>
                </div>
            </div>
        </div>
        <div class="invitational_tips">
            <span class="invitation-title">邀请小技巧</span>
        </div>
        <div class="look-over">
            <div class="look-over-item">
                <h5 class="look-over-item-title">已获得书豆</h5>
                <p class="look-over-item-text">{{coin}}</p>
                <div class="look-over-item-button" @click.stop="promptly">立即提现</div>
            </div>
            <div class="look-over-line"></div>
            <div class="look-over-item">
                <h5 class="look-over-item-title">我的好友</h5>
                <p class="look-over-item-text">{{user}}</p>
                <div class="look-over-item-button" @click.stop="lookFirend">查看好友</div>
            </div>
        </div>
        <div class="rule-modal" v-if="modalShow" @click.stop="closeModal">
            <div class="rule-modal-box" @click.stop>
                <h5 class="rule-modal-box-h5">活动规则</h5>
                <img src="../assets/image/close.png" alt="" class="close" @click="closeModal">
                <div v-html="content"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Home',
        data() {
            return {
                inviteData: [],
                content: '',
                modalShow: false,
                user: '0',
                coin: '0',
                user_id: '',
            }
        },
        created() {
            let url = this.getUrl();
            this.user_id = url.uid;
            this.$axios({
                url: '/invite/rule',
                method: 'get',
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                let data = res.data.data;
                this.inviteData = data.rule;
                this.user = data.user;
                this.coin = data.coin;
            }).catch((err) => {
                alert(err.message)
            });

            this.$axios({
                url: '/illustration/index',
                method: 'get',
                params: {
                    mark: 'invite'
                }
            }).then((res) => {
                let data = res.data.data;
                this.content = data.content
            }).catch((err) => {
                alert(err.message)
            });

        },
        methods: {
            lookRule() {
                this.modalShow = true
            },
            closeModal() {
                this.modalShow = false
            },
            // 点击立即邀请按钮
            inviteApp() {
                var u = window.navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid) {
                    Android.invite()
                }
                if (isIOS) {
                    this.$bridge.callhandler('invite', '', (data) => {
                        // 处理返回数据
                    })
                }
            },
            closeInvite() {
                var u = window.navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid) {
                    Android.hiddenInvite()
                }
                if (isIOS) {
                    this.$bridge.callhandler('hiddenInvite', '', (data) => {
                        // 处理返回数据
                    })
                }
            },
            //查看好友
            lookFirend() {
                this.$router.push({
                    path: '/myfirend',
                    query: {
                        user_id: this.user_id
                    }
                })
            },
            //提现
            promptly() {
                var u = window.navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid) {
                    Android.withdraw()
                }
                if (isIOS) {
                    this.$bridge.callhandler('withdraw', '', (data) => {
                        // 处理返回数据
                    })
                }
            }
        }
    }
</script>
<style scope lang="less">

    #home {
        min-height: ~"calc(2146px + 360px)";
        width: 750px;
        position: relative;
        background: #ff7690;

        .bg-img {
            width: 750px;
            position: absolute;
            left: 0;
            top: 0;
        }

        .invitation-title {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            position: absolute;
            left: 50%;
            top: 10px;
            transform: translateX(-50%);
        }

        .invite1-box {
            width: 700px;
            height: 547px;
            background: url("../assets/image/invite1_bg.png");
            background-size: 100% 100%;
            position: absolute;
            top: 236px;
            left: 25px;
            text-align: center;
            padding-top: 60px;
            box-sizing: border-box;

            .invite1-hint {
                font-size: 66px;
                line-height: 60px;
                font-family: PingFang SC;
                font-weight: bold;
                color: rgba(245, 50, 94, 1);
                margin-bottom: 30px;
            }

            .invite1-hint:after {
                content: '';
                display: inline-block;
                width: 382px;
                height: 1px;
                background: rgba(255, 207, 193, 1);
                opacity: 0.6;
            }

            .invite1-text {
                font-size: 71px;
                line-height: 60px;
                font-family: PingFang SC;
                font-weight: bold;
                color: rgba(245, 50, 94, 1);
                position: relative;
            }

            .invite1-text:after {
                content: '';
                display: inline-block;
                width: 539px;
                height: 1px;
                background: rgba(255, 207, 193, 1);
                opacity: 0.4;
            }

            .invite1-text:before {
                content: '可变现';
                position: absolute;
                width: 76px;
                height: 31px;
                background: rgba(255, 191, 57, 1);
                border-radius: 18px 15px 15px 0px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                line-height: 31px;
                text-align: center;
                right: 60px;
                top: -31px;
            }

            .invite1-explain {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 60px;
            }

            .invite1-button {
                width: 575px;
                height: 130px;
                background: url("../assets/image/invite_button.png");
                background-size: 100% 100%;
                margin-left: 69px;
                font-size: 34px;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                line-height: 120px;
            }
        }

        .invitation_reward {
            width: 690px;
            height: 374px;
            background: url("../assets/image/invitation_reward_bg.png");
            background-size: 100% 100%;
            position: absolute;
            top: 1159px;
            left: 30px;

            .invitation_reward-box {
                margin-top: 150px;
                display: flex;
                justify-content: space-around;

                .invitation_reward-box-item {
                    .invitation_reward-box-item-top {
                        width: 154px;
                        height: 138px;
                        background: url("../assets/image/Invitation_reward_day.png");
                        background-size: 100% 100%;
                        text-align: center;
                        margin-left: 50%;
                        transform: translateX(-50%);

                        .invitation_reward-price {
                            line-height: 60px;
                            font-size: 20px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: rgba(255, 67, 100, 1);

                            .invitation_reward-number {
                                font-size: 30px;
                                font-family: HanZhenGuangBiao-Gadget;
                                font-weight: 400;
                            }
                        }

                        .invitation_reward-day {
                            line-height: 76px;
                            font-size: 24px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: rgba(255, 67, 100, 1);
                        }
                    }

                    .invitation_reward-box-item-bottom {
                        font-size: 26px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: rgba(245, 50, 94, 1);
                        line-height: 50px;
                    }
                }
            }
        }

        .invitation_process {
            width: 690px;
            height: 404px;
            background: url("../assets/image/invitation process.png");
            background-size: 100% 100%;
            position: absolute;
            top: 1574px;
            left: 30px;
            text-align: center;

            .invitation_process-bottom {
                width: 100%;
                display: flex;
                justify-content: space-around;
                position: absolute;
                bottom: 50px;

                .invitation_process-bottom-item {
                    width: 200px;
                    font-size: 28px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }

        .invitational_tips {
            width: 700px;
            height: 353px;
            background: url("../assets/image/invitational_tips_bg.png");
            background-size: 100% 100%;
            position: absolute;
            top: 2019px;
            left: 25px;
        }

        .rule {
            width: 150px;
            height: 60px;
            background: url("../assets/image/acti_rule.png");
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 153px;
            font-size: 26px;
            font-family: PingFang;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 60px;
        }

        .rule-modal {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            overflow: scroll;

            .rule-modal-box-h5 {
                text-align: center;
                line-height: 60px;
                font-size: 32px;
            }

            .rule-modal-box {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100px;
                width: 90%;
                background: white;
                font-size: 26px;
                padding: 20px;
                border-radius: 10px;
                box-sizing: border-box;
                margin-bottom: 50px;

                .close {
                    width: 50px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }

        .look-over {
            width: 690px;
            height: 300px;
            border: 10px solid #fea0b2;
            position: absolute;
            top: 819px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 14px;
            box-sizing: border-box;
            background-image: linear-gradient(#fffeff, #fff9ec);
            display: flex;
            align-items: center;
            justify-content: space-around;

            .look-over-item {
                text-align: center;

                .look-over-item-title {
                    font-size: 30px;
                    font-weight: 500;
                }

                .look-over-item-text {
                    font-size: 40px;
                    color: #333333;
                    font-weight: 900;
                    padding: 20px 0;
                }

                .look-over-item-button {
                    border: 1px solid #ff4364;
                    color: #ff4364;
                    font-size: 26px;
                    width: 170px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 40px;
                }
            }

            .look-over-line {
                width: 2px;
                height: 140px;
                background: #e8e6e2;
            }
        }
    }

    .modalShow {
        height: 100% !important;
        overflow: hidden !important;
    }
</style>

